import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.filter("toFixed", (n, digits) => {
  return Number(n.toFixed(digits)).toLocaleString("da-DK");
});
Vue.filter("monthText", n => {
  switch (n) {
    case 1:
      return "januar";
    case 2:
      return "februar";
    case 3:
      return "marts";
    case 4:
      return "april";
    case 5:
      return "maj";
    case 6:
      return "juni";
    case 7:
      return "juli";
    case 8:
      return "august";
    case 9:
      return "september";
    case 10:
      return "oktokber";
    case 11:
      return "november";
    case 12:
      return "december";
  }
});

new Vue({
  render: h => h(App)
}).$mount("#app");
