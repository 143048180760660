<template>
  <div id="calculator">
    <b-row class="mt-5">
      <b-col>
        <h2>Beregning efter nettoprisindekset</h2>
        <p>
          Det danske nettoprisindeks anvendes især af private og offentlige
          virksomheder til regulering af kontrakter og til regulering af
          husleje. Nettoprisindekset anvendes derudover til regulering af beløb
          i en række love og bekendtgørelser mv. Nettoprisindekset
          offentliggøres den 10. i den efterfølgende måned eller den først
          kommende hverdag derefter.
        </p>
        <p>
          Grundlaget for nettoprisindekset er de faktiske priser friholdt for
          skatter, moms, punktafgifter og tillagt tilskud med det formål at
          nedsætte prisen.
        </p>
        <p>
          Beregneren anvendes ved at indtaste det beløb der skal reguleres og
          derefter start indeks og slut indeks.
        </p>
        <b-form-group label="Beløb">
          <b-input-group>
            <b-form-input v-model.number="amount" type="number" step=".01" />
            <b-input-group-append is-text>kr.</b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Fra indeks">
          <b-input-group>
            <b-form-select v-model="fromYear" v-bind:options="yearOptions" />
            <b-form-select
              v-model="fromMonth"
              v-bind:options="fromMonthOptions"
            />
            <b-input-group-append v-if="fromIndex" is-text>{{
              fromIndex | toFixed(1)
            }}</b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group label="Til indeks">
          <b-input-group>
            <b-form-select v-model="toYear" v-bind:options="yearOptions" />
            <b-form-select v-model="toMonth" v-bind:options="toMonthOptions" />
            <b-input-group-append v-if="toIndex" is-text>{{
              toIndex | toFixed(1)
            }}</b-input-group-append>
          </b-input-group>
        </b-form-group>
        <label>
          Ændring: {{ change | toFixed(2) }} kr. ({{
            percentageChange | toFixed(3)
          }}%) </label
        ><br />
        <label
          >Justeret beløb:
          <strong>{{ newAmount | toFixed(2) }} kr.</strong>
        </label>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col center>
        <small>Datakilde: <a href="http://dst.dk">dst.dk</a></small>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import orderBy from "lodash/orderBy";

function monthToText(n) {
  switch (n) {
    case 1:
      return "januar";
    case 2:
      return "februar";
    case 3:
      return "marts";
    case 4:
      return "april";
    case 5:
      return "maj";
    case 6:
      return "juni";
    case 7:
      return "juli";
    case 8:
      return "august";
    case 9:
      return "september";
    case 10:
      return "oktober";
    case 11:
      return "november";
    case 12:
      return "december";
  }
}

export default {
  name: "Calculator",
  props: ["index"],
  data() {
    return {
      amount: null,
      fromYear: null,
      fromMonth: null,
      toYear: null,
      toMonth: null
    };
  },

  computed: {
    byYear() {
      if (this.index === null) {
        return null;
      }
      return groupBy(this.index, e => e.year);
    },

    fromIndex() {
      if (
        this.byYear === null ||
        this.fromYear === null ||
        this.fromMonth === null
      ) {
        return null;
      }
      return this.byYear[this.fromYear][this.fromMonth - 1].value;
    },

    toIndex() {
      if (
        this.byYear === null ||
        this.toYear === null ||
        this.toMonth === null
      ) {
        return null;
      }
      return this.byYear[this.toYear][this.toMonth - 1].value;
    },

    newAmount() {
      if (this.amount && this.fromIndex && this.toIndex) {
        return (this.amount / this.fromIndex) * this.toIndex;
      }
      return 0;
    },

    change() {
      if (this.amount && this.fromIndex && this.toIndex) {
        return this.newAmount - this.amount;
      }
      return 0;
    },

    yearOptions() {
      if (this.byYear === null) {
        return [];
      }
      return orderBy(keys(this.byYear), e => e, "desc");
    },

    fromMonthOptions() {
      if (this.byYear === null || this.fromYear === null) {
        return [];
      }
      return this.byYear[this.fromYear].map(e => ({
        value: e.month,
        text: monthToText(e.month)
      }));
    },

    toMonthOptions() {
      if (this.byYear === null || this.toYear === null) {
        return [];
      }
      return this.byYear[this.toYear].map(e => ({
        value: e.month,
        text: monthToText(e.month)
      }));
    },

    percentageChange() {
      if (!this.amount || !this.newAmount) {
        return 0;
      }
      return ((this.newAmount - this.amount) / this.amount) * 100;
    }
  }
};
</script>

<style></style>
