<template>
  <div id="app">
    <b-container>
      <b-row id="calculator-row">
        <b-col>
          <Calculator v-bind:index="index" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import Calculator from "./components/Calculator.vue";

export default {
  name: "App",

  components: {
    Calculator
  },

  data() {
    return {
      index: null
    };
  },

  mounted() {
    axios
      .get("https://www.nettoprisindeks.dk/.netlify/functions/main")
      .then(res => {
        this.index = res.data;
      });
  }
};
</script>

<style></style>
